<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box account_input">
        <div class="accountright_cont_box">

          <div class="flex_center_between_box" style="">
            <h2 class="history_box-head">Payment Records</h2>
            <div style="display: flex;">
              <button class=" flex_center todo_btn_mr10" :class="Dowload.length==0?'btn_disabled':'btn_blue'" :disabled="Dowload.length==0">
    
              <span v-if="Dowload.length==0"> Trading Issues</span>
            <a v-else  style="color: #fff;"  :href="'mailto:service@csibizinfo.com?subject=information need to correct&body=Customer service, hello: %0D%0Aand If you have any questions about the selected single payment order or multiple payment %0D%0Aand orders, please enter your questions below：'+Dowload[0]?.email+Dowload[0]?.createTime+Dowload[0]?.paymentAmount+'%0D%0Aand Please help to handle it as soon as possible. thank you' ">
              Trading Issues
            </a>
              </button>
              <button class="flex_center todo_btn" @click="getInvoicePdfAddress()" :class="Dowload.length==0?'btn_disabled':'btn_blue'" :disabled="Dowload.length==0">
                Download Invoice
              </button>
            </div>
          </div>
          <div class="his_sel_box common_checkbox" style="width: 100%">
            <el-table class="spec_table table_height_30 table_font_size_14" :data="yourUsagelist" style="width: 100%"  @selection-change="handleSelectionChange" empty-text="No Data">
              <el-table-column type="selection" width="55" :selectable="selectable" :aria-disabled="true">
              </el-table-column>
              <el-table-column prop="createTime" label="Date" align="left">
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="paymentAmount" label="User Account" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="paymentAmount" label="Payment Amount" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="table-content " style="">
                    {{ scope.row.paymentAmount }}
                  </div>
                </template>
              </el-table-column>

            </el-table>
            <div v-show="this.page.total>this.page.pageSize" style="text-align: center; padding-top: 20px">
              <span class="contclass show_All" @click="apptablelist">Show All</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import { getUserRechargesPage, getInvoicePdfAddress } from "@/api/userApi";
import ReLogin from "../../../components/ReLogin.vue";

export default {
  mixins: [mixins1],
  components: {
    ReLogin
  },
  data() {
    return {
      reLoginVis: false,
      value1: "",
      CategoryVal: "",
      yourUsagelist: [],
      window: window,
      page: {
        pageSize: 10,
        pageNum: 1,
      },
      Dowload:[]
    };
  },
  beforeDestroy() { },
  destroyed() { },
  mounted() {
    this.getgetUserActionLogPage();
   },

  methods: {
    selectable(row) {
      
      // 这里可以根据行数据的条件返回 true 或 false
      return row.userInvoiceId == null; // 例如，只有价格小于 250 的行可以被选中
    },
    getgetUserActionLogPage() {
       getUserRechargesPage({
        // email: this.CategoryVal,
        // actualAmount: this.CategoryVal1,
        // startTime: this.datePicker == null ? "" : this.datePicker[0],
        // endTime: this.datePicker == null ? "" : this.datePicker[1],
        page: this.page.pageNum,
        pageSize:this.page.pageSize,
      }).then((res) => {
        
        if (res.code == 200) {
          this.yourUsagelist = res.data.rows;
          this.page.total=res.data.total
        } else {
          this.yourUsagelist = [];
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });

    },
    getInvoicePdfAddress(){
      let userInvoiceIdArr=[]
      this.Dowload.forEach((item,index)=>{
        if(item.userInvoiceId==null){
          userInvoiceIdArr.push(item.id)
        }
        
      })
    
      getInvoicePdfAddress({ids:userInvoiceIdArr.join(',')}).then(res=>{
        if(res.code==200){
          window.open(res.data, '_blank');
          this.$router.go(0);

        }else{
          
          this.$message.error(res.msg);
        }
        
      })
      
    },
    handleSelectionChange(val) { 
      this.Dowload=val
      
     },
     apptablelist() {
      this.page.pageSize=  this.page.pageSize+5

   this.getgetUserActionLogPage()
    }
  
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.form-email {
  display: flex;
  width: 100%;
}

.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.item_txtcolor1 {
  color: #1290c9;
}

.item_txtcolor2 {
  color: #55b419;
}

.item_txtcolor3 {
  color: #ffa000;
}

.das_top_item .color1 {
  background: rgba(18, 144, 201, 0.12);
}

.das_top_item .color2 {
  background: rgba(85, 180, 25, 0.12);
}

.das_top_item .color3 {
  background: rgba(255, 160, 0, 0.12);
}

.das_top_item .color4 {
  background: #ff7600;
}

.das_top_item .item_img img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: left;
}

.das_top_item .item_group {
  margin-left: 0.7142857142857143rem;
  font-size: 1.2857142857142858rem;

  /* width: 17.142857142857142rem; */
}

.das_top_item .item_lab {
  display: block;
  color: #1a2332;

  margin-top: 0.35714285714285715rem;
}

.item_txt {
  font-size: 2.142857142857143rem;
  text-align: center;
}

.cont_m {
  margin-top: 2.142857142857143rem;
  margin-bottom: 5.714285714285714rem;
}

.country_box {
  margin-top: 4.285714285714286rem;
}

.country_box .title {
  margin-bottom: 0;
}

.title {
  margin-bottom: 1.4285714285714286rem;
}

.title_txt {
  font-size: 1.1428571428571428rem;
  font-family: 'DMSans Bold';
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.c_d_buttom {
  justify-content: flex-end;
  /* margin-top: 1.4285714285714286rem; */
  margin-bottom: 0;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}

.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.1428571428571428rem;
}

.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.das_top_item {
  width: 32.5%;
  padding: 1.4285714285714286rem;
  /* border-radius: 1rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
}

.das_top_item .item_img {
  width: 4.285714285714286rem;
  height: 4.285714285714286rem;
  line-height: 5.571428571428571rem;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: center;
}

.top_tab {
  margin-top: 2.857142857142857rem;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* line-height: 2.142857142857143rem; */
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
}

.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}

.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0.07142857142857142rem dashed #d9d9d9;
  border-radius: 0.42857142857142855rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 1.4285714285714286rem;
  color: #8c939d;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
}

/deep/ .avatar {
  width: 5rem;
  height: 5rem;
  display: block;
}

/* 日历大小 */
.el-range-editor.el-input__inner {
  /* width: 16rem !important; */
  padding: 0.21428571428571427rem 0.21428571428571427rem;
}

.el-date-editor /deep/ .el-range__icon {
  font-size: 1.1428571428571428rem;
  margin-left: -0.35714285714285715rem;
  color: #1A2332;
  /* float: left; */
  line-height: 2.2857142857142856rem;
  position: relative;
  left: 47%;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
}
</style>